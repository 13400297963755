import { useTranslation } from "react-i18next";

export const getStatusColor = (status, theme) => {
  switch (status) {
    case "placed":
      return theme.palette.rgbColor.draft;
    case "failed":
      return theme.palette.rgbColor.red;
    case "scheduled":
      return theme.palette.rgbColor.yellow;
    case "published":
      return theme.palette.rgbColor.green;
    case "rejected":
      return "black";
    case "in_review":
      return "orange";
    default:
      return "";
  }
};

export const useGetStatusText = () => {
  const { t } = useTranslation();

  const statusText = {
    scheduled: t("commonTexts.scheduled"),
    published: t("commonTexts.commonBtn.publishText"),
    failed: t("contentCalender.topBtn.infoColors.text3"),
    placed: t("contentCalender.topBtn.infoColors.text2"),
    rejected: t("contentCalender.topBtn.infoColors.text5"),
    in_review: t("contentCalender.topBtn.infoColors.text4")
  };
  return statusText;
};

export const platToIconsMap = {
  fb: (
    <img
      className='platform-icon'
      style={{ width: "100%" }}
      src={process.env.PUBLIC_URL + "/facebook.svg"}
      alt=''
    />
  ),
  ig: (
    <img
      className='platform-icon'
      style={{ width: "100%" }}
      src={process.env.PUBLIC_URL + "/instagram.png"}
      alt=''
    />
  ),
  igs: (
    <img
      className='platform-icon'
      style={{ width: "100%" }}
      src={process.env.PUBLIC_URL + "/igStory.png"}
      alt=''
    />
  ),
  fbg: (
    <img
      className='platform-icon'
      style={{ width: "100%" }}
      src={process.env.PUBLIC_URL + "/fbgroup1.png"}
      alt=''
    />
  ),
  fbr: (
    <img
      className='platform-icon'
      style={{ width: "100%" }}
      src={process.env.PUBLIC_URL + "/facebook-reel.svg"}
      alt=''
    />
  ),
  li: (
    <img
      className='platform-icon'
      style={{ width: "100%" }}
      src={process.env.PUBLIC_URL + "/linkedin.png"}
      alt=''
    />
  ),
  pi: (
    <img
      className='platform-icon'
      style={{ width: "100%" }}
      src={process.env.PUBLIC_URL + "/pinterest.png"}
      alt=''
    />
  ),
  tt: (
    <img
      className='platform-icon'
      style={{ width: "100%" }}
      src={process.env.PUBLIC_URL + "/tik-tok.png"}
      alt=''
    />
  ),
  twt: (
    <img
      className='platform-icon'
      style={{ width: "100%" }}
      src={process.env.PUBLIC_URL + "/twitter-x.svg"}
      alt=''
    />
  ),
  gmb: (
    <img
      className='platform-icon'
      style={{ width: "100%" }}
      src={process.env.PUBLIC_URL + "/gmb.png"}
      alt=''
    />
  ),
  yt: (
    <img
      className='platform-icon'
      style={{ width: "100%" }}
      src={process.env.PUBLIC_URL + "/youtube.png"}
      alt=''
    />
  )
};
