import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jsonLoaded: false,
  polotnoJson: {},
  singleImageAllTemplates: { byId: {}, allIds: [] },
  singleImagePreferredTemplates: { byId: {}, allIds: [] },
  carouselAllTemplates: { byId: {}, allIds: [] },
  carouselPreferredTemplates: { byId: {}, allIds: [] },
  loaderCenter: false,
  variant_aspects: [],
  current_aspect: {},
  fromPublish: false,
  disabledRatios: [],
  customSize: { height: 512, width: 512 }
};

const setJsonLoaded = (state, action) => {
  state.jsonLoaded = action.payload;
};
const setLoaderCenter = (state, action) => {
  state.loaderCenter = action.payload;
};

const setPolotnoJson = (state, action) => {
  state.polotnoJson = action.payload;
};
const resetPolotnoJson = (state) => {
  state.polotnoJson = {};
};
const setSIPreferredTemplates = (state, action) => {
  if (action.payload.reset) {
    state.singleImagePreferredTemplates.allIds = [];
  }
  action.payload?.templates?.forEach((d) => {
    state.singleImagePreferredTemplates.byId[d.template_id] = {
      ...d,
      preferred: true
    };
    state.singleImagePreferredTemplates.allIds.push(d.template_id);
  });
};
const resetSITemplates = (state) => {
  state.singleImagePreferredTemplates = { byId: {}, allIds: [] };
  state.singleImageAllTemplates = { byId: {}, allIds: [] };
};

const updateSIPreferredTemplates = (state, action) => {
  const { payload } = action;
  const { template_data, mode } = payload;
  if (mode) {
    state.singleImagePreferredTemplates.allIds.push(template_data.template_id);
    state.singleImageAllTemplates.byId[template_data.template_id]["priority"] = 1;
  } else {
    state.singleImagePreferredTemplates.allIds = state.singleImagePreferredTemplates.allIds.filter(
      (_id) => _id !== template_data.template_id
    );
    try {
      // for cases where preferred template is not loaded in current pages all templates
      state.singleImageAllTemplates.byId[template_data.template_id]["priority"] = 2;
    } catch (error) {}
  }
  state.singleImagePreferredTemplates.byId[template_data.template_id] = {
    ...template_data,
    preferred: mode
  };
};

const setSIAllTemplates = (state, action) => {
  if (action.payload.reset) {
    state.singleImageAllTemplates.allIds = [];
  }
  action.payload.templates.forEach((d) => {
    state.singleImageAllTemplates.byId[d.template_id] = d;
    state.singleImageAllTemplates.allIds.push(d.template_id);
  });
};

const setCarouselPreferredTemplates = (state, action) => {
  action.payload.forEach((d) => {
    state.carouselPreferredTemplates.byId[d.template_id] = {
      ...d,
      preferred: true
    };
    state.carouselPreferredTemplates.allIds.push(d.template_id);
  });
};
const resetCarouselTemplates = (state) => {
  state.carouselPreferredTemplates = { byId: {}, allIds: [] };
  state.carouselAllTemplates = { byId: {}, allIds: [] };
};

const updateCarouselPreferredTemplates = (state, action) => {
  const { payload } = action;
  const { template_data, mode } = payload;
  if (mode) {
    state.carouselPreferredTemplates.allIds.push(template_data.template_id);
    state.carouselAllTemplates.byId[template_data.template_id]["priority"] = 1;
  } else {
    state.carouselPreferredTemplates.allIds = state.singleImagePreferredTemplates.allIds.filter(
      (_id) => _id !== template_data.template_id
    );
    state.carouselAllTemplates.byId[template_data.template_id]["priority"] = 2;
  }
  state.carouselPreferredTemplates.byId[template_data.template_id] = {
    ...template_data,
    preferred: mode
  };
};

const setCarouselAllTemplates = (state, action) => {
  action.payload.forEach((d) => {
    state.carouselAllTemplates.byId[d.template_id] = d;
    state.carouselAllTemplates.allIds.push(d.template_id);
  });
};

const setVariantAspects = (state, action) => {
  const { payload } = action;
  switch (payload.mode) {
    case "set":
      state.variant_aspects = payload.data;
      break;
    case "push":
      state.variant_aspects = [...state.variant_aspects, ...payload.data];
      break;
    case "reset":
      state.variant_aspects = [];
      break;
    case "updateProperty":
      state.variant_aspects = state.variant_aspects.map((variant) => {
        if (payload.aspect.variant_aspect === variant.variant_aspect) {
          return { ...variant, [payload.property]: payload.data };
        }
        return variant;
      });
      break;
  }
};

const setCurrentAspect = (state, action) => {
  state.current_aspect = action.payload;
};

const setFromPublish = (state, action) => {
  state.fromPublish = action.payload;
};

const setDisabledRatios = (state, action) => {
  state.disabledRatios = action.payload;
};

const setCustomSize = (state, action) => {
  const { payload } = action;
  if (payload.mode === "reset") {
    state.customSize = { height: 512, width: 512 };
  } else {
    state.customSize = { ...state.customSize, ...payload };
  }
};

const polotnoStatesSlice = createSlice({
  name: "polotnoStates",
  initialState,
  reducers: {
    setJsonLoaded,
    setPolotnoJson,
    resetPolotnoJson,
    setSIPreferredTemplates,
    setSIAllTemplates,
    resetSITemplates,
    updateSIPreferredTemplates,
    setCarouselPreferredTemplates,
    setCarouselAllTemplates,
    resetCarouselTemplates,
    updateCarouselPreferredTemplates,
    setLoaderCenter,
    setVariantAspects,
    setCurrentAspect,
    setFromPublish,
    setDisabledRatios,
    setCustomSize,
    resetPolotnoStates: () => initialState
  }
});

export const { resetPolotnoStates } = polotnoStatesSlice.actions;
export default polotnoStatesSlice.reducer;
