import { createContext, useCallback, useState } from "react";
import { check_inFrame } from "../components/SignIn/HelperFunctions";
import { keyMirror } from "../utils/common";
import { localStorageSafe } from "../utils/localStorageUtils";

const EmbedContext = createContext();

export default EmbedContext;
export const MODES = keyMirror("NO_EMBED", "SIMPLE", "DETAILED");
export const embedPostType = keyMirror("text", "business");

export function EmbedContextProvider({ children, embedAppId }) {
  const [embedConfig, setEmbedConfig] = useState(null);
  const isEmbeded = check_inFrame() && !!embedAppId;

  const getMode = useCallback(() => {
    if (embedConfig && isEmbeded) {
      return embedConfig.mode || MODES.SIMPLE;
    }
    return "";
  }, [embedConfig, isEmbeded]);
  const closeFrame = useCallback(() => {
    window.top.postMessage({ source: "predis-embed", action: "close" }, "*");
    localStorageSafe("removeItem", "appId");
  }, []);

  return (
    <EmbedContext.Provider
      value={{
        isEmbeded,
        app_id: embedAppId,
        setEmbedConfig: setEmbedConfig,
        embedConfig: embedConfig,
        getMode,
        closeFrame
      }}
    >
      {children}
    </EmbedContext.Provider>
  );
}
