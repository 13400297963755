// there are various issue with using the broadcastChannel API or using window.postMessage
// broadcastChannel API doesn't work in semrush/iframe
// window.postMessage doesn't work with facebook and ig since it destroys the window object and hence window.opener.postMessage doesn't work in mobile browsers
// in some scenarios popup.close is not working in mobile browsers so we need to use window.close in the popup window itself
// sometimes fasle popup.closed is returned in safari
// hence we are using a polyfill to handle this issue via localstorage for mobile and dorectly using popup window for desktop
// it has only limitation of not working in iframe + incognito/other strict mode of browsers where i/o of localstorage is no allowed

const oauthAsyncListener = (use_opener = false, { oauth_url, oauth_popup }) => {
  //   const currentOrigin = window.location.origin;
  //   const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  //   if (isMobile) {
  // listen to sessionstorage changes with key and get value on available, add timeout to reject if not available
  let timeout = null;
  let checkStorage = null;
  //   if (use_opener && !oauth_popup)
  //     return Promise.reject(new Error("Popup required for opener flow"));
  if (!oauth_popup) throw new Error("Popup required for opener flow");
  oauth_popup.location = oauth_url;
  if (!use_opener) {
    return new Promise((resolve, reject) => {
      // to check if user has not finished last oauth flow and again started a new one
      const lastOauthData = retrieveOAuthDataFromSession();
      if (lastOauthData && lastOauthData.status === "in_progress") {
        checkStorage && clearInterval(checkStorage);
        timeout && clearTimeout(timeout);
        localStorage.removeItem("oauth_params");
        // return reject(new Error("Another OAuth flow in progress"));
      }
      startOauthSession();
      checkStorage = setInterval(() => {
        const oauthData = retrieveOAuthDataFromSession();
        if (oauthData && oauthData.status === "success") {
          clearInterval(checkStorage);
          timeout && clearTimeout(timeout);
          localStorage.removeItem("oauth_params");
          return resolve(oauthData.data);
        }
      }, 500);
      timeout = setTimeout(
        () => {
          clearInterval(checkStorage);
          const err = new Error("Timed out");
          err.name = "TimeoutError";
          reject(err);
        },
        5 * 60 * 1000
      ); // 5 minutes
    });
  } else {
    return oauthAsyncPopup(oauth_popup);
  }
  //   } else {
  //     return new Promise((resolve, reject) => {
  //       if (!oauth_popup) {
  //         return reject(new Error("Popup blocked"));
  //       }
  //       const checkPopup = setInterval(() => {
  //         console.log("checking", oauth_popup.location);
  //         try {
  //           const closeCondition = redirectUrl
  //             ? oauth_popup.location.href.startsWith(redirectUrl)
  //             : oauth_popup.location.origin === currentOrigin;
  //           if (closeCondition) {
  //             clearInterval(checkPopup);
  //             const search_params = new URLSearchParams(oauth_popup.location.search);
  //             const all_params = Object.fromEntries(search_params);
  //             oauth_popup.close();
  //             resolve(all_params);
  //           }
  //         } catch (e) {
  //           // cross origin issues - ignore
  //         }
  //         if (oauth_popup.closed) {
  //           clearInterval(checkPopup);
  //           reject(new Error("Popup closed by user"));
  //         }
  //       }, 500);
  //     });
  //   }
};

export const oauthAsyncPopup = (oauth_popup, redirectUrl) => {
  //   const currentOrigin = window.location.origin;
  //   const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  //   if (isMobile) {
  // listen to sessionstorage changes with key and get value on available, add timeout to reject if not available
  const currentOrigin = window.location.origin;
  return new Promise((resolve, reject) => {
    if (!oauth_popup) {
      return reject(new Error("Popup blocked"));
    }
    const timeout = setTimeout(
      () => {
        return reject(new Error("Timed out"));
      },
      5 * 60 * 1000
    ); // 5 minutes
    const checkPopup = setInterval(() => {
      try {
        const closeCondition = redirectUrl
          ? oauth_popup.location.href.startsWith(redirectUrl)
          : oauth_popup.location.origin === currentOrigin;
        if (closeCondition) {
          clearInterval(checkPopup);
          const search_params = new URLSearchParams(oauth_popup.location.search);
          const all_params = Object.fromEntries(search_params);
          oauth_popup.close();
          clearTimeout(timeout);
          return resolve({ ...all_params, user_action_required: false });
        }
      } catch (e) {
        // cross origin issues - ignore, means the popup is has other origin open
      }
      if (oauth_popup.closed) {
        clearInterval(checkPopup);
        resolve({ user_action_required: true });
      }
    }, 500);
  });
};

export const storeOAuthDataInSession = (data) => {
  const url = data.search;
  const queryParams = new URLSearchParams(url);

  // Combine query and fragment parameters
  const authParams = Object.fromEntries(queryParams);

  // Store the auth params in sessionStorage
  try {
    localStorage.setItem("oauth_params", JSON.stringify({ data: authParams, status: data.status }));
  } catch (e) {
    console.error("Error storing OAuth data in sessionStorage", e);
  }
};

// Utility to retrieve OAuth data from sessionStorage
export const retrieveOAuthDataFromSession = () => {
  const storedData = localStorage.getItem("oauth_params");
  return storedData ? JSON.parse(storedData) : null;
};

export const startOauthSession = () => {
  localStorage.setItem("oauth_params", JSON.stringify({ status: "in_progress" }));
};

export default oauthAsyncListener;
